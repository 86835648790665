import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";



export default function Tab5() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);

    useEffect(() => {
        let dat = { uid: auth._id, type: "Spirituality" };
        dispatch(GetTabsData(dat));
    }, []);

    console.log('spiii', data);




    return (
        <div class="set">
            <div class="row">

                <div class="col-lg-4 col-sm-6">
                    <h4>Spiritual Organisation Affiliation</h4>
                    <p>{data && data.affiliated_with_spiritual_organization ? data.affiliated_with_spiritual_organization : "-"}</p>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>How Long? </h4>
                    <p>{data && data.affiliated_duration ? data.affiliated_duration : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How much time you spend daily on your spiritual sadhana?</h4>
                    <p>{data && data.sadhanaTime ? data.sadhanaTime : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Since when have you been practicing Krishna Consciousness?</h4>
                    <p>{data && data.practicing_time_period ? data.practicing_time_period : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Chanting rounds?</h4>
                    <p>{data && data.chanting_rounds ? data.chanting_rounds : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How often do you visit temple?</h4>
                    <p>{data && data.temple_visit ? data.temple_visit : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Spirituality's role in your life?</h4>
                    <p>{data && data.spirituality_role ? data.spirituality_role : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Who is your spiritual master?</h4>
                    <p>{data && data.spiritual_master ? data.spiritual_master : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Are you initiated?</h4>
                    <p>{data && data.initiated ? data.initiated : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Initiation Name</h4>
                    <p>{data && data.Initiation_name ? data.Initiation_name : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Do you follow Four Regulative Principles
                    </h4>
                    <p>{data && data.four_Regulative_Principles ? data.four_Regulative_Principles : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Do your parents practice Krishna Consciousness?
                    </h4>
                    <p>{data && data.parents_practice_Krishna ? data.parents_practice_Krishna : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>List of Prabhupad's book read by you.</h4>
                    {data && data.selectedBooks && data.selectedBooks.length > 0 ?
                        <ul>
                            {data.selectedBooks.map((item, i) => (
                                <li>{item.value}</li>
                            ))}

                        </ul>
                        : "-"}
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>What Srila Prabhupada book are you currently reading?

                    </h4>
                    <p>{data && data.currently_reading ? data.currently_reading : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Do you observe fasting on Ekadashi days?*

                    </h4>
                    <p>{data && data.ekadashi_fasting ? data.ekadashi_fasting : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Which ISKCON/HKM Centre are you associated with?*

                    </h4>
                    <p>{data && data.iskcon_associted_with ? data.iskcon_associted_with : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How are you associated with the temple?

                    </h4>
                    <p>{data && data.temple_associated_type ? data.temple_associated_type : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?
                    </h4>
                    <p>{data && data.attended_seminar ? data.attended_seminar : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Are you open to engaging in devotional services or seva together as a couple?
                    </h4>
                    <p>{data && data.engaged_devotional_services ? data.engaged_devotional_services : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How important is living in close proximity to a temple or devotional community for you?
                    </h4>
                    <p>{data && data.living_in_temple ? data.living_in_temple : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Are you open to further deepening your understanding and practice of Krishna Consciousness after marriage?
                    </h4>
                    <p>{data && data.Krishna_Consciousness_after_marriage ? data.Krishna_Consciousness_after_marriage : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>What is your understanding and acceptance of the principles and teachings of Srila Prabhupada?
                    </h4>
                    <p>{data && data.tech_of_Srila_Prabhupada ? data.tech_of_Srila_Prabhupada : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How do you envision supporting and encouraging each other's spiritual growth within the marriage?*
                    </h4>
                    <p>{data && data.spritulGrowthMarriage ? data.spritulGrowthMarriage : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How do you envision incorporating Krishna Consciousness into your married life?
                    </h4>
                    <p>{data && data.incorporating_Krishna_Consciousness ? data.incorporating_Krishna_Consciousness : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>How do you handle challenges or conflicts in your spiritual journey?
                    </h4>
                    <p>{data && data.conflicts_spiritualJourny ? data.conflicts_spiritualJourny : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Do you have any specific spiritual goals or
                        aspirations that you would like to share?
                    </h4>
                    <p>{data && data.specific_spiritual_goals ? data.specific_spiritual_goals : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Do you have a spiritual mentor or guide? If yes, please provide
                        details?
                    </h4>
                    <p>{data && data.spiritual_mentor ? data.spiritual_mentor : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>What temple service are you engaged in?
                    </h4>
                    <p>{data && data.temple_services ? data.temple_services : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Department Name?
                    </h4>
                    <p>{data && data.department_name ? data.department_name : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>Designation?
                    </h4>
                    <p>{data && data.designation_name ? data.designation_name : "-"}</p>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <h4>What are the various roles and experiences you have had in your
                        service at the temple?
                    </h4>
                    <p>{data && data.roles_and_experiences ? data.roles_and_experiences : "-"}</p>
                </div>
            </div>
        </div>

    )
}